body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Easily customizable colors for the web app */
:root {
  --main-background-color: #F9FBF2;
  --main-top-background-color: #FBFEF9;
  --main-accent-color: #FB8F67;
  --title-color: #1E2019;
}

#product-catalog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.product-title {
  font-size: 2em;
}

.nav-item {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
}

.nav-navbar a{
  height: 100%;
}
